<template>
  <div class="integralExchange">
    <Header :hideGoBack="true" :title="title" theme="light" />
    <div class="container__main">
      <div
        class="wrap__content"
        v-for="(item, i) in productsData"
        :key="i"
        @click="
          $router.push({
            path: '/integralExchangeConfirm',
            query: {
              cardQuota: item['cardQuota'],
              price: item['price'],
              id: item['id']
            },
          })
        "
      >
        <div class="box__img" v-if="i % 2 != 1">
          <img
            src="../../assets/images/public/ic_points_mall_orange.webp"
            alt=""
          />
        </div>
        <div class="box__img" v-else>
          <img
            src="../../assets/images/public/ic_points_mall_red.webp"
            alt=""
          />
        </div>
        <div class="box__text">
          <div>¥</div>
          <div>{{ item.cardQuota }}</div>
          <div>{{ item.price }}积分兑换</div>
        </div>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "integralExchange",
  components: {
    Header,
    FooterMenu,
  },
  data() {
    return {
      title: "积分兑换",
      data: [],
    };
  },
  computed: {
    ...mapState("products", ["productsData"]),
  },
  watch: {
    productsData(e) {
      this.data = e;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("products", ["getProducts"]),
    init() {
      this.getProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
.integralExchange {
  width: 100%;
  .container__main {
    height: calc(92vh - 72px);
    overflow-y: scroll;
    .wrap__content {
      width: 100%;
      position: relative;
        @media (min-width:480px){
          width: 50%;
        margin: auto;
        }

      &:nth-child(odd) {
        color: var(--orange);
      }
      &:nth-child(even) {
        color: var(--red);
      }
      .box__img {
        width: 100%;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .box__text {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        div {
        }
        div:nth-child(1) {
          align-self: start;
        }
        div:nth-child(2) {
          font-size: 3.5rem;
        }
        div:nth-child(3) {
          align-self: flex-end;
        }
      }
    }
  }
}
</style>
